import React from "react";
import theme from "theme";
import { Theme, Text, Em, Box, Hr, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Барбершоп UrbanTrim
			</title>
			<meta name={"description"} content={"Створіть свій стиль, вдосконаліть свій вигляд"} />
			<meta property={"og:title"} content={"Барбершоп UrbanTrim"} />
			<meta property={"og:description"} content={"Створіть свій стиль, вдосконаліть свій вигляд"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="30%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Наші послуги
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--base">
					У UrbanTrim ми не просто стрижемо волосся – ми створюємо враження, які визначають і покращують ваш особистий стиль. Наш комплексний спектр послуг розроблено для того, щоб задовольнити всі ваші потреби щодо догляду, забезпечуючи, щоб ви виходили з дому впевнено та виглядали якнайкраще. Наші досвідчені перукарі використовують найсучасніші інструменти та техніки, щоб досягти точних, стильних результатів, створених саме для вас.
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--base">
					<Em>
						Створіть свій фірмовий стиль разом з нами – де кожна деталь має значення.
					</Em>
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="68%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Точні стрижки
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Класичний крій: Освячені часом стилі, вдосконалені десятиліттями.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Сучасний стиль: Модний і сучасний образ для свіжого вигляду.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Індивідуальний дизайн: Спеціально розроблений відповідно до вашого індивідуального стилю та вподобань.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Розкішні послуги гоління
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Традиційне вологе гоління: Класичне перукарське гоління з гарячими рушниками та кремом для гоління преміум-класу.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Підстригання та укладка бороди:  Формуйте та укладайте свою бороду, від повної бороди до деталізованих цапиних борідок.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Лікування та догляд за волоссям
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Догляд за шкірою голови: Живильний догляд для здорового росту волосся та здоров’я шкіри голови.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Фарбування волосся: від тонких відблисків до повної зміни кольору за допомогою ніжних, високоякісних барвників.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Кондиціонуючі процедури: Відновіть життєву силу за допомогою глибокого кондиціонування, адаптованого до вашого типу волосся.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Додаткові засоби догляду
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Підстригання брів: Очистіть або сформуйте брови для чіткого, доглянутого вигляду.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Стрижка носа та вух: Швидке та непомітне видалення небажаного волосся.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Дізнайтеся більше на UrbanTrim
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Це лише короткий огляд того, що ми пропонуємо в UrbanTrim. Для повного розуміння повного спектру наших послуг та індивідуальних консультацій ми запрошуємо вас відвідати нас або зв’язатися. Дізнайтеся, як наша віддана команда може підняти ваш догляд на новий рівень.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});